import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import {IconSearch, IconX} from "@tabler/icons";
import {styled} from '@mui/material/styles';
import {useEffect, useState} from "react";
import useContacts from "./context/useContacts";
import {IconButton} from "@mui/material";

const CustomTextField = styled(TextField)`
    & .MuiOutlinedInput-notchedOutline {
        border-radius: 40px;
    }
`

type ContactSearchProps = {
    onSearch?: (event: { value: string }) => void
}

export const ContactSearch = (props: ContactSearchProps) => {
    const contactsHolder = useContacts();
    const [searchTerm, setSearchTerm] = useState<string>('');

    useEffect(() => {
        const timeout = setTimeout(() => {
            contactsHolder.search(searchTerm);
        }, 300);

        return () => clearTimeout(timeout);
    }, [searchTerm]);

    return (
        <Box sx={(theme) => ({
            padding: '15px 20px',
            background: theme.palette.grey["50"]
        })}>
            <CustomTextField
                fullWidth
                label="Search person"
                id="fullWidth"
                placeholder={"Name, phone or email"}
                sx={{
                    'MuiOutlinedInput': {
                        background: 'black'
                    }
                }}
                value={searchTerm}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position={"start"}>
                            <IconSearch/>
                        </InputAdornment>
                    ),
                    endAdornment: (
                        searchTerm ? (
                            <InputAdornment position={"end"}>
                                <IconButton onClick={() => {
                                    setSearchTerm('');
                                    contactsHolder.search('');
                                }}>
                                    <IconX/>
                                </IconButton>
                            </InputAdornment>
                        ) : null
                    )
                }}
                margin={"normal"}
                onChange={(event: any) => {
                    setSearchTerm(event.target.value);
                    if (typeof props.onSearch === 'function') {
                        props.onSearch(event.target.value);
                    }
                }}
            />
        </Box>
    );
}