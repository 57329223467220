import type {RandomApiResponse} from "../../types/RandomApiResponse";
import type {RandomUserEntry} from "../../types/RandomUserEntry";
import { faker } from '@faker-js/faker';

const USERS_PER_PAGE: number = 50;


export const getUsers = async (page: number = 1): Promise<RandomUserEntry[]> => {
    // NOTE: the randomuser.me service doesn't provide reliable way of getting ids
    //  so making requests for getting information about specific person is impossible
    //  as such, I have to fetch the data only one, assuming the app doesn't require pagination
    //  and the data from the single fetch should be enough
    const resp = await fetch(`https://randomuser.me/api/?page=${page}&results=${USERS_PER_PAGE}&seed=abc`);

    const json: RandomApiResponse = await resp.json();

    // NOTE: also since the results do not include unique id,
    //  I'm assigning id by myself using index position in which it came from the service
    return json.results.map((entry, ind) => {
        entry._id = ind;
        entry.position = faker.name.jobTitle();
        return entry;
    })
}