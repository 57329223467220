import type {ContactsHolder} from "../../../../types/ContactsHolder";
import {useEffect, useState, useTransition} from "react";
import type {RandomUserEntry} from "../../../../types/RandomUserEntry";
import {getUsers} from "../../../apis/users";
import Fuse from 'fuse.js';

// since the way we deal with randomuser service we can cache the contacts
let ALL_CONTACTS_CACHE: RandomUserEntry[] = [];
let contactLoadingStatus = false;

//
// this hook provides contacts state management functionality that
// should be shared with different components. In more advance project some
// library like recoiljs or mobx would be used.
//
export default function useContactsProvider(): ContactsHolder {
    const [loading, setLoading] = useState<boolean>(false);
    const [contacts, setContacts] = useState<RandomUserEntry[]>([]);
    const [,startTransition] = useTransition();

    useEffect(() => {
        async function run() {
            try {
                if (!contactLoadingStatus && !ALL_CONTACTS_CACHE.length) {
                    await fetchContacts();
                }
            } catch (error) {
                console.error(error);
            }
        }

        run();
    }, []);

    const fetchContacts = async () => {
        try {
            setLoading(true);
            contactLoadingStatus = true;
            const resp = await getUsers();
            ALL_CONTACTS_CACHE = resp;
            setContacts(() => {
                return [...resp];
            });
        } finally {
            setLoading(false);
            contactLoadingStatus = false;
        }
    }

    const search = async (searchTerm: string) => {
        try {
            if (!searchTerm) {
                setContacts(() => [...ALL_CONTACTS_CACHE]);
                return;
            }
            // ideally it should be separate request to the server,
            // but since we assume that the data that came from the first fetch is enough,
            // I use fuzzy search to filter out the contact list
            const fuse = new Fuse(ALL_CONTACTS_CACHE, {
                includeScore: true,
                keys: [{
                    name: 'name.first',
                }, {
                    name: 'name.last'
                }, {
                    name: 'phone'
                }, {
                    name: 'email'
                }]
            });

            startTransition(() => {
                const results = fuse
                    .search(searchTerm);

                setContacts(() => {
                    return results
                        .filter((item) => {
                            return item && parseFloat(`${item.score}`) < 0.1;
                        })
                        .map((searchItem) => searchItem.item);
                });
            });
        } catch (error) {
            console.error(error);
        }
    }

    const getContact = (contactId: number): RandomUserEntry | undefined => {
        return ALL_CONTACTS_CACHE.find((item) => {
            return item._id === contactId
        });
    }

    return {
        contacts: contacts,
        isLoading: loading,
        search,
        getContact
    };
}