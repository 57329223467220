import type {RandomUserEntry} from "../../../types/RandomUserEntry";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

type ProfileProps = {
    contact: RandomUserEntry;
};

export const Profile = (props: ProfileProps) => {
    const address = `${props.contact.location.city}, ${props.contact.location.street.name} ${props.contact.location.postcode}`;

    return (
        <Box sx={{
            padding: '40px',
            height: '100vh',
            overflowY: 'auto'
        }}>
            <Grid container spacing={2}>
                <Grid item lg={4} xs={12}>
                    <img
                        src={props.contact.picture.large}
                        style={{
                            maxWidth: '100%',
                            width: '100%'
                        }}
                        alt={`${props.contact.name.first} ${props.contact.name.last}`}
                    />
                </Grid>
                <Grid item lg={8} xs={12}>
                    <Stack>
                        <Typography variant={"h3"} sx={{
                            fontWeight: 500
                        }}>
                            {props.contact.name.title} {props.contact.name.first} {props.contact.name.last}
                        </Typography>
                        <Typography variant={"h5"} sx={(theme) => ({
                            textTransform: 'uppercase',
                            fontWeight: 300,
                            color: theme.palette.text.disabled
                        })}>
                            {props.contact.position}
                        </Typography>
                        <Table sx={{
                            margin: '20px 0',
                            width: '100%'
                        }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"} width={"100"}>Phone:</TableCell>
                                    <TableCell><a
                                        href={`tel:${props.contact.phone}`}>{props.contact.phone}</a> ({props.contact.location.country})
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"}>Email:</TableCell>
                                    <TableCell><a
                                        href={`mailto:${props.contact.email}`}>{props.contact.email}</a></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"}>Address:</TableCell>
                                    <TableCell>{address}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"}>Timezone:</TableCell>
                                    <TableCell>{props.contact.location.timezone.offset} ({props.contact.location.timezone.description})</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"}>Age:</TableCell>
                                    <TableCell>{(new Date(
                                        props.contact.dob.date)).toLocaleDateString()} ({props.contact.dob.age} years
                                        old)
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};