import type {PropsWithChildren} from "react";
import {AreaSideA} from "./AreaSideA";
import {AreaMain} from "./AreaMain";
import {CONTACT_LAYOUT_AREA_MAIN, CONTACT_LAYOUT_AREA_SIDE_A} from "./consts";
import {ComponentWithStatic} from "../../../types/ComponentWithStatic";
import Box from "@mui/material/Box";
import {useParams} from "react-router-dom";

type ContactLayoutProps = {}

type ContactLayoutComponent = ComponentWithStatic<PropsWithChildren<ContactLayoutProps>,
    {
        AreaSideA: typeof AreaSideA,
        AreaMain: typeof AreaMain
    }>

//
// ContactLayout responsible for the main application grid, which holds all UI elements.
// It doesn't have to include anything else but layout specific styles.
//
// ContactLayout also includes 2 predefined areas: AreaSideA and AreaMain. one is for contact list
// and second one for profile data view.
// In mobile view only one area is available for users.
//
export const ContactLayout: ContactLayoutComponent = ((props: PropsWithChildren<ContactLayoutProps>) => {
    const {contactId} = useParams();

    return (
        <Box
            component={"section"}
            sx={(theme) => ({
                display: 'grid',
                gridTemplateAreas: `"${contactId ? CONTACT_LAYOUT_AREA_MAIN : CONTACT_LAYOUT_AREA_SIDE_A}"`,
                gridTemplateColumns: '100%',
                position: 'fixed',
                width: '100%',
                left: 0,
                top: 0,
                right: 0,
                bottom: 0,
                overflow: 'hidden',
                [theme.breakpoints.up('sm')]: {
                    gridTemplateAreas: `"${CONTACT_LAYOUT_AREA_SIDE_A} ${CONTACT_LAYOUT_AREA_MAIN}"`,
                    gridTemplateColumns: 'minmax(360px, 30%) 1fr',
                }
            })}
        >
            {props.children}
        </Box>
    );
}) as any

ContactLayout.AreaSideA = AreaSideA;
ContactLayout.AreaMain = AreaMain;