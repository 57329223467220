import {Profile} from "../components/Profile/Profile";
import useContacts from "../components/Contacts/context/useContacts";
import {useNavigate, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";

const ProfileSkeleton = () => {
    return (
        <Box sx={{
            padding: '40px',
            width: '100%'
        }}>
            <Grid container spacing={2}>
                <Grid item lg={4} xs={12}>
                    <Skeleton variant={"rectangular"} sx={{
                        maxWidth: '100%',
                        width: '100%',
                        minHeight: '300px'
                    }}/>
                </Grid>
                <Grid item lg={8} xs={12}>
                    <Stack>
                        <Typography variant={"h2"}>
                            <Skeleton/>
                        </Typography>
                        <Typography variant={"h4"}>
                            <Skeleton/>
                        </Typography>
                        <Table sx={{
                            margin: '20px 0',
                            width: '100%'
                        }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"} width={"100"}>Phone:</TableCell>
                                    <TableCell>
                                        <Skeleton variant={"rectangular"}/>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"}>Email:</TableCell>
                                    <TableCell><Skeleton variant={"rectangular"}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"}>Address:</TableCell>
                                    <TableCell><Skeleton variant={"rectangular"}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"}>Timezone:</TableCell>
                                    <TableCell><Skeleton variant={"rectangular"}/></TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component={"th"} align={"left"}>Age:</TableCell>
                                    <TableCell>
                                        <Skeleton variant={"rectangular"}/>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

export default function ProfilePage() {
    const contactHolder = useContacts();
    let {contactId} = useParams();
    const navigate = useNavigate();

    const contact = contactHolder.getContact(parseInt(`${contactId}`, 10));

    if (!contact) {
        return (
            <ProfileSkeleton/>
        );
    }

    return (
        <Box sx={{
            position: 'relative',
            width: '100%'
        }}>
            <Profile contact={contact}/>
            <IconButton
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px'
                }}
                onClick={() => {
                    navigate('/');
                }}
            >
                <CloseIcon fontSize={"large"}/>
            </IconButton>
        </Box>
    );
}