import {ContactLayout} from "../components/ContactLayout";
import {Contacts} from "../components/Contacts";
import {ContactsProvider} from "../components/Contacts/context/ContactsProvider";
import {Outlet, ScrollRestoration, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function App() {
    const {contactId} = useParams();
    return (
        <ContactsProvider>
            <ContactLayout>
                <ContactLayout.AreaSideA>
                    <Contacts>
                        <Contacts.Search/>
                        <Contacts.List />
                    </Contacts>
                </ContactLayout.AreaSideA>
                <ContactLayout.AreaMain>
                    {!contactId ? (
                        <Box sx={{
                            width: '100%',
                            padding: '40px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Typography variant={"h2"}>Select contact to view it.</Typography>
                        </Box>
                    ) : null}
                    <Outlet />
                </ContactLayout.AreaMain>
            </ContactLayout>
            <ScrollRestoration/>
        </ContactsProvider>
    );
}

export default App;
