import {PropsWithChildren} from "react";
import {CONTACT_LAYOUT_AREA_MAIN} from "./consts";
import Box from "@mui/material/Box";

type AreaMainProps = {}

export const AreaMain = (props: PropsWithChildren<AreaMainProps>) => {
    return (
        <Box
            component={"main"}
            sx={{
                gridArea: CONTACT_LAYOUT_AREA_MAIN,
                display: 'flex',
                alignItems: 'stretch',
                width: '100%'
            }}
        >
            {props.children}
        </Box>
    );
}