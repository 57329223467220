import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './routes/App';
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {CssBaseline, GlobalStyles, ThemeProvider} from "@mui/material";
import theme from "./theme";
import '@fontsource/roboto/latin.css';
import ProfilePage from "./routes/ProfilePage";
import ErrorPage from "./routes/ErrorPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "contacts/:contactId",
                element: <ProfilePage />,
                errorElement: <ErrorPage/>
            }
        ]
    },
    {
        path: "contacts/:id",
        element: <ProfilePage />,
        errorElement: <ErrorPage/>
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <GlobalStyles styles={{
                '::-webkit-scrollbar': {
                    width: '10px',
                    backgroundColor: '#f5f5f5'
                },
                '::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)'
                },
                '::-webkit-scrollbar-track': {
                    backgroundColor: 'rgba(255, 255, 255, 0.08)'
                },
                'a': {
                    color: '#0079f4',
                    textDecoration: 'none',
                    backgroundImage: 'linear-gradient(rgba(4, 85, 119, .13), rgba(4, 85, 119, .13))',
                    backgroundRepeat: 'repeat-x',
                    backgroundSize: '100% 1px',
                    backgroundPosition: '0 100%'
                },
                'a:hover': {
                    color: '#bd1d00',
                    backgroundImage: 'linear-gradient(rgba(189, 29, 0, .10), rgba(189, 29, 0, .10))'
                }
            }}/>
            <RouterProvider router={router}/>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
