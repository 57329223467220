import type {RandomUserEntry} from "../../../types/RandomUserEntry";
import ListItemButton from "@mui/material/ListItemButton";
import {ContactListItem} from "./ContactListItem";
import Divider from "@mui/material/Divider";
import {Fragment, useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";

export type ContactListItemsProps = {
    items: RandomUserEntry[];
    onSelectContact?: (person: RandomUserEntry) => void;
}

export const ContactListItems = (props: ContactListItemsProps) => {
    const [selectedContact, setSelectedContact] = useState<RandomUserEntry | null>(null);
    const navigate = useNavigate();
    const {contactId} = useParams();
    const ref = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (ref.current !== null) {
            ref.current.scrollIntoView({
                // behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
        }
    }, [])

    if (!Array.isArray(props.items) || !props.items.length) {
        return (
            <>
                <Box sx={{
                    padding: '55px 15px',
                    width: '100%',
                }}>
                    <Typography sx={{
                        textAlign: 'center'
                    }}>
                        <i>No contacts.</i>
                    </Typography>
                </Box>
                <Divider variant={"fullWidth"}/>
            </>
        );
    }
    return (
        <>
            {
                props.items.map((item, ind) => {
                    const isSelected = (selectedContact !== null && selectedContact._id === item._id) || (item._id
                        === parseInt(`${contactId}`, 10));
                    return (
                        <Fragment key={`contactlist-items-item-${item._id}-${item.email}-${item.phone}`}>
                            <ListItemButton
                                disableGutters
                                onClick={() => {
                                    navigate(`/contacts/${item._id}`)
                                    setSelectedContact(item);
                                    if (typeof props.onSelectContact === 'function') {
                                        props.onSelectContact(item);
                                    }
                                }}
                                selected={isSelected}
                                ref={isSelected ? ref : null}
                            >
                                <ContactListItem
                                    item={item}
                                />
                            </ListItemButton>
                            <Divider variant={"fullWidth"}/>
                        </Fragment>
                    );
                })
            }
        </>
    );
}
