import {PropsWithChildren} from "react";
import {ContactList} from "./ContactList";
import {ContactSearch} from "./ContactSearch";
import Box from "@mui/material/Box";
import {ComponentWithStatic} from "../../../types/ComponentWithStatic";

type ContactsProps = {}

type ContactsComponent = ComponentWithStatic<PropsWithChildren<ContactsProps>,
    {
        List: typeof ContactList,
        Search: typeof ContactSearch,
    }>

//
// Contacts component is a component that can hold the list specific components.
// It doesn't limit from using other components inside (for example, like ContactLayout), but also
// includes 2 preconfigured components to use in conjunction: ContactList and ContactSearch
//
export const Contacts: ContactsComponent = ((props: PropsWithChildren<ContactsProps>) => {
    return (
        <Box component={"section"} sx={{
            display: 'flex',
            alignItems: 'stretch',
            width: '100%',
            height: '100%',
            flexDirection: 'column'
        }}>
            {props.children}
        </Box>
    );
}) as any

Contacts.List = ContactList;
Contacts.Search = ContactSearch;