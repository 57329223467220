import type {PropsWithChildren} from "react";
import {CONTACT_LAYOUT_AREA_SIDE_A} from "./consts";
import Box from "@mui/material/Box";
import {useParams} from "react-router-dom";
import {useMediaQuery, useTheme} from "@mui/material";

type AreaSideAProps = {}

export const AreaSideA = (props: PropsWithChildren<AreaSideAProps>) => {
    const {contactId} = useParams();
    const theme = useTheme();
    const match = useMediaQuery(theme.breakpoints.up('sm'));
    if (contactId && !match) {
        return null;
    }
    return (
        <Box
            component={"aside"}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
                gridArea: CONTACT_LAYOUT_AREA_SIDE_A,
                maxHeight: '100vh'
            }}
        >
            {props.children}
        </Box>
    );
}