import type {RandomUserEntry} from "../../../types/RandomUserEntry";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

type ContactListItemProps = {
    item: RandomUserEntry
}
export const ContactListItem = (props: ContactListItemProps) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '15px',
            paddingRight: '15px'
        }}>
            <Box component={"div"} sx={{width: 70, height: 70, borderRadius: '50%', overflow: 'hidden'}}>
                <img
                    src={props.item.picture.medium}
                    alt={`${props.item.name.title} ${props.item.name.first} ${props.item.name.last}`}
                    loading={"lazy"}
                />
            </Box>
            <Box component={"section"} sx={{
                marginLeft: '15px'
            }}>
                <Typography variant="subtitle1" sx={{
                    lineHeight: 1
                }}>
                    {props.item.name.first} {props.item.name.last}
                </Typography>
                <Typography variant="overline">
                    {props.item.position}
                </Typography>
                <Box component={"table"} sx={{
                    fontSize: '0.8rem'
                }}>
                    <tbody>
                    <tr>
                        <td align={"left"}>Phone:</td>
                        <td>{props.item.phone} ({props.item.location.country})</td>
                    </tr>
                    <tr>
                        <td align={"left"}>Email:</td>
                        <td>{props.item.email}</td>
                    </tr>
                    </tbody>
                </Box>
            </Box>
        </Box>
    );
}