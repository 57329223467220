import ContactsContext from "./ContactsContext";
import {PropsWithChildren} from "react";
import useContactsProvider from "./useContactsProvider";

export const ContactsProvider = (props: PropsWithChildren) => {
    const contacts = useContactsProvider();
    return (
        <ContactsContext.Provider value={contacts}>
            {props.children}
        </ContactsContext.Provider>
    );
}