import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export const ContactListItemSkeleton = () => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: '15px',
            paddingBottom: '15px',
            paddingLeft: '15px',
            paddingRight: '15px'
        }}>
            <Skeleton variant={"circular"} width={70} height={70}/>
            <Box component={"section"} sx={{
                marginLeft: '15px'
            }}>
                <Skeleton variant={"text"} sx={{fontSize: '1.1rem'}} width={200}/>
                <Skeleton variant={"text"} sx={{fontSize: '1rem', marginBottom: '2px'}} width={100}/>
                <Box component={"table"} sx={{
                    fontSize: '0.8rem',
                    width: '100%'
                }}>
                    <tbody>
                    <tr>
                        <td align={"left"} width={50}>Phone:</td>
                        <td><Skeleton variant={"text"} sx={{fontSize: '0.8rem'}}/></td>
                    </tr>
                    <tr>
                        <td align={"left"} width={50}>Email:</td>
                        <td><Skeleton variant={"text"} sx={{fontSize: '0.8rem'}}/></td>
                    </tr>
                    </tbody>
                </Box>
            </Box>
        </Box>
    );
}