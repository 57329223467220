import type {PropsWithChildren} from "react";
import {ContactListItem} from "./ContactListItem";
import {ContactListItemSkeleton} from "./ContactListItemSkeleton";
import List from "@mui/material/List";
import {ContactListItems} from "./ContactListItems";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import {Fragment} from "react";
import useContacts from "./context/useContacts";
import {RandomUserEntry} from "../../../types/RandomUserEntry";

const _identity = () => {
}

type ContactListProps = PropsWithChildren<{
    onSelectContact?: (person: RandomUserEntry) => void
}>

const ContactListSkeleton = () => {
    return (
        <>
            {Array(10).fill(0).map((i, ind) => {
                return (
                    <Fragment key={`contactlist-${ind}-skeleton-item`}>
                        <ContactListItemSkeleton/>
                        <Divider variant={"fullWidth"}/>
                    </Fragment>
                );
            })}
        </>
    );
}

export const ContactList = (props: ContactListProps) => {
    const contactsHolder = useContacts();

    return (
        <Box sx={{
            overflowY: !contactsHolder || contactsHolder?.isLoading ? 'hidden' : 'scroll',
            width: '100%',
            flex: 1,
            background: '#fcfcfc'
        }}>
            <List sx={{
                padding: 0,
                background: '#fcfcfc'
            }}>
                {!contactsHolder || contactsHolder?.isLoading ? (
                    <ContactListSkeleton/>
                ) : (
                    <ContactListItems
                        items={contactsHolder.contacts}
                        onSelectContact={props.onSelectContact ?? _identity}
                    />
                )}
            </List>
            {props.children}
        </Box>
    );
}

ContactList.Item = ContactListItem;

